<template>
  <div>
    <v-container class="mb-4">
      <v-row class="align-center justify-center">
        <v-btn-toggle
          v-if="!$_hasRole('student')"
          v-model="view"
          dense
          mandatory
        >
          <v-btn text value="panel">Spiegel</v-btn>
          <v-btn text value="list">Liste</v-btn>
        </v-btn-toggle>

        <v-spacer></v-spacer>

        <EmailChip
          class="mr-2"
          title="E-Mail an Klasse"
          :value="studentsDL"
        ></EmailChip>
      </v-row>
    </v-container>
    <template v-if="view == 'panel'">
      <v-card>
        <v-system-bar>Klassenspiegel</v-system-bar>
        <v-card-text>
          <PeoplePanel :value="schoolClass.students" />
        </v-card-text>
      </v-card>
    </template>
    <template v-if="view == 'list'">
      <v-card>
        <v-system-bar>Klassenliste</v-system-bar>

        <v-list v-if="$vuetify.breakpoint.xs">
          <PersonItem
            v-for="student in schoolClass.students"
            :value="student"
            :key="student.id"
            flags
          >
          </PersonItem>
        </v-list>

        <v-data-table
          v-else
          :items="schoolClass.students"
          :headers="headers"
          disable-filtering
          disable-pagination
          hide-default-footer
        >
          <template v-slot:item.student="{ item }">
            <PersonItem small hideExt :value="item" flags></PersonItem>
          </template>
          <template
            v-for="headerItem in headers.filter((item) => item.description)"
            v-slot:[`header.${headerItem.value}`]="{ header }"
          >
            <v-tooltip top :key="'header' + headerItem.value">
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">{{ header.text }} </span>
              </template>
              <span>{{ header.description }}</span>
            </v-tooltip>
          </template>
          <template
            v-for="headerItem in headers.filter((item) => item.lookup)"
            v-slot:[`item.${headerItem.value}`]="{ value }"
          >
            <v-tooltip top :key="'item' + headerItem.value">
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on"
                  >{{ value ? value.code : "" }}
                </span>
              </template>
              <span>{{ value ? value.description : "" }}</span>
            </v-tooltip>
          </template>
          <template v-slot:footer
            ><TableFooter
              :items="schoolClass.students"
              label="Schüler:innen"
              labelSingular="Schüler:in"
          /></template>
        </v-data-table>
      </v-card>
    </template>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import EmailChip from "common/components/EmailChip.vue";
import PersonItem from "common/components/PersonItem.vue";
import PeoplePanel from "common/components/PeoplePanel.vue";
import TableFooter from "common/components/TableFooter.vue";

export default defineComponent({
  name: "SchoolClassList",
  components: { EmailChip, PersonItem, PeoplePanel, TableFooter },
  props: ["schoolClass"],
  computed: {
    studentsDL() {
      return this.schoolClass.code
        ? `klasse_${this.schoolClass.code.toLowerCase()}@mygymer.ch`
        : "";
    },
  },
  data() {
    return {
      view: "list",
      headers: [
        {
          text: "Klasse",
          value: "schoolClass.code",
        },
        {
          text: "Name",
          value: "student",
        },
        {
          text: "BI",
          description: "zweisprachiger Unterricht",
          value: "bilingual",
          lookup: true,
          sort: this.compareLookup,
        },
        {
          text: "KU",
          description: "Kunstfach",
          value: "artsSubject",
          lookup: true,
          sort: this.compareLookup,
        },
        {
          text: "S3",
          description: "Dritte Sprache",
          value: "thirdLanguage",
          lookup: true,
          sort: this.compareLookup,
        },
        {
          text: "SF",
          description: "Schwerpunktfach",
          value: "majorSubject",
          lookup: true,
          sort: this.compareLookup,
        },
        {
          text: "SF+",
          description: "Schwerpunktfach plus",
          value: "majorSubjectPlus",
          lookup: true,
          sort: this.compareLookup,
        },
        {
          text: "EF",
          description: "Ergänzungsfach",
          value: "complementarySubject",
          lookup: true,
          sort: this.compareLookup,
        },
      ],
    };
  },
  methods: {
    compareLookup(a, b) {
      return a.code.localeCompare(b.code, "de-CH");
    },
  },
});
</script>
